const sliderData = [
  {
    author: 'Bret Taylor',
    company: 'Retired President & COO, Salesforce',
    text: `"Thanks to companies like bizdin ai, a lot of these SaaS tools are building intelligent capabilities into their products[...] that's a path for a lot of companies to get value from Al without becoming Al shops, which is a really formidable challenge."`,
  },
  {
    author: 'Bret Taylor',
    company: 'Retired President & COO, Salesforce',
    text: `"Thanks to companies like bizdin ai, a lot of these SaaS tools are building intelligent capabilities into their products[...] that's a path for a lot of companies to get value from Al without becoming Al shops, which is a really formidable challenge."`,
  },
  {
    author: 'Bret Taylor',
    company: 'Retired President & COO, Salesforce',
    text: `"Thanks to companies like bizdin ai, a lot of these SaaS tools are building intelligent capabilities into their products[...] that's a path for a lot of companies to get value from Al without becoming Al shops, which is a really formidable challenge."`,
  },
  {
    author: 'Bret Taylor',
    company: 'Retired President & COO, Salesforce',
    text: `"Thanks to companies like bizdin ai, a lot of these SaaS tools are building intelligent capabilities into their products[...] that's a path for a lot of companies to get value from Al without becoming Al shops, which is a really formidable challenge."`,
  },
];

export default sliderData;
