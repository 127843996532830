import partner1 from '../../assets/images/Frame 7.png';
import partner2 from '../../assets/images/marina-bay-sands-seeklogo 2 2.png';
import partner3 from '../../assets/images/Nvidia-White-Horizontal-Dark-Background-Logo.wine-removebg-preview.png';
import partner4 from '../../assets/images/Frame 10.png';

const partners = [
  {
    src: partner1,
    alt: 'Partner 1',
  },
  {
    src: partner2,
    alt: 'Partner 2',
  },
  {
    src: partner3,
    alt: 'Partner 3',
  },
  {
    src: partner4,
    alt: 'Partner 4',
  },
];

export default partners;
